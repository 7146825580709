import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Price } from "../../magento/peregrine/lib";
import Quantity from "../ProductQuantity/quantity";
import styles from './style.module.css';

export default function CartItems({items, updateItem, updatingItemId}) {
    return (
        <ul className={styles.itemList}>
            {items.map(item => <CartItem key={item.id} item={item} updateItem={updateItem} updatingItemId={updatingItemId} />)}
        </ul>
    );
}

function CartItem({item, updateItem, updatingItemId}) {
    const product = item.product;

    const ItemStats = () => {
        if (item.id===updatingItemId) {
            return <div className={styles.stats}><center><FontAwesomeIcon icon={faSpinner} spin size="lg" /></center></div>
        }

        return(
            <div className={styles.stats}>
                <div>
                    <strong>Quantity: </strong>
                    <ItemQuantity item={item} updateItem={updateItem} />                
                </div>
                <div className={styles.itemValues}>
                    <span>Item price: <Price currencyCode={item.prices.price.currency} value={item.prices.price.value} /></span><br/>
                    <strong>Subtotal: <Price currencyCode={item.prices.row_total.currency} value={item.prices.row_total.value} /></strong>
                </div>
            </div>
        );
    };

    return(
        <li className={styles.item}>
            <div className={styles.image}><img src={product.ont_image_url_1} /></div>
            <div className={styles.details}>
                <div>
                    <h3>{product.name}</h3>
                    <ExtraInfo item={item} />
                </div>
                <div>
                    <a onClick={() => updateItem(item.id, 0)}>Remove from basket</a>
                </div>
            </div>
            <ItemStats />
        </li>
    );
}

function ExtraInfo({item}) {
    if (item.configurable_options?.length) {
        return (
            <ul className={styles.extraInfo}>
                {item.configurable_options.map((option,i) => 
                    <li key={i}>{option.option_label}: {option.value_label}</li>)}
            </ul>
        );
    }

    if (item.bundle_options?.length) {
        return (
            <ul className={styles.bundleOptions}>
                {item.bundle_options.map(option => <IncludedBundleOption key={option.id} option={option} />)}
            </ul>
        );
    }

    return null;
}

function IncludedBundleOption({option}) {
    return option.values.map(selection =>
        <li key={selection.id}>{selection.quantity}x {selection.label}</li>
    );
}

function ItemQuantity({item, updateItem}) {
    if (['simple'].includes(item.product.type_id)) {
        return(
            <div className={styles.itemQty}>
                <Quantity
                    value={item.quantity}
                    onValueChange={newQty => updateItem(item.id, newQty)}
                />
            </div>  
        );
    }

    return item.quantity;
}
