export function saveSection(code, checkout, setCheckout, oldSection, newSection) {
    setCheckout({
        ...checkout,
        activeStep: checkout.activeStep+1,
        submitStatus: '',
        [code]: {
            ...oldSection,
            ...newSection
        }
    });
}

export function getFileBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    })
}

export function pluralise(amount, word) {
    const plural = word.endsWith('y')
        ? (word.substr(0, word.length - 1) + 'ies')
        : (word + 's');

    return (amount <= 1)
        ? amount + ' ' + word
        : amount + ' ' + plural;
}

export function getTotalCarriage(customerCart) {
    if (customerCart?.shipping_addresses) {
        return customerCart.shipping_addresses.reduce((acc,curr) => {
            return (acc + (curr.selected_shipping_method?.amount.value ?? 0));
        }, 0);
    }

    return 0;
}

export function getConsumablesCarriage(shippingSchedule) {
    if (shippingSchedule.cost_per_shipment && shippingSchedule.shipments) {
        return (shippingSchedule.cost_per_shipment * shippingSchedule.shipments.length);
    }

    return 0;
}

export function getDeviceCarriage(customerCart, shippingSchedule) {
    const totalCarriage = getTotalCarriage(customerCart);
    const consumablesCarriage = getConsumablesCarriage(shippingSchedule);
    
    if (consumablesCarriage > 0 && totalCarriage > consumablesCarriage) {
        return (totalCarriage - consumablesCarriage);
    }

    return 0;
}

export function getCartTaxAmount(cart)
{
    const productValue = cart.prices.subtotal_with_discount_excluding_tax.value;
    const totalShipping = cart.shipping_addresses[0]?.selected_shipping_method?.amount?.value ?? 0;
    const taxableValue = productValue + totalShipping;
    const grandTotal = cart.prices.grand_total.value;
    const currency = cart.prices.grand_total.currency;
    const taxValue = grandTotal - taxableValue;
    
    return {
        currency: currency,
        value: taxValue,
    };
}
