import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import React, { useState } from 'react';
import { useCartContext } from '../../contexts/CartContext';
import { useCart, useUpdateItem, updateContextCart } from '../../hooks/useCart';
import CartItems from './cartItems';
import CartSummary from './cartSummary';
import styles from './style.module.css';

export default function Cart(props) {
    return(
        <div className={styles.cart}>
            <div className={styles.continue}>
                <Link to="/">
                    <FontAwesomeIcon icon={faChevronLeft} />&nbsp;&nbsp;Continue shopping
                </Link>
            </div>
            <h1>Shopping basket</h1>
            <CartContent />
        </div>
    );
}

function CartContent() {
    const [updatingItemId, setUpdatingItemId] = useState(0);
    const { cart, setCart, resetCart } = useCartContext(); 
    const { loading, error, data } = useCart(cart.id);
    const [updateItemMutation] = useUpdateItem(cart.id);

    if (loading) {
        return <p>Loading ...</p>;
    }

    if (error) {
        if (cart?.id?.length) resetCart();
        return <p>Your basket is empty.</p>;
    }

    if (!data?.cart?.items?.length) {
        return <p>Your basket is empty.</p>;
    }

    function updateItem(itemId, qty) {
        setUpdatingItemId(itemId);
        updateItemMutation({variables: {cartId: cart.id, itemId: itemId, qty: qty}})
            .then(data => {
                updateContextCart(cart, setCart, data);
                setUpdatingItemId(0);
            })
            .catch(error => console.log('Error updating cart item', error));
    }

    return(
        <div className={styles.cartContent}>
            <CartItems items={data.cart.items} updateItem={updateItem} updatingItemId={updatingItemId} />
            <CartSummary cartData={data.cart} />
        </div>
    );
}
